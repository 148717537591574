import React from "react";
import Style from "./loader.module.css";
// styles
const initial = {
    strokeDashoffset: "0px"
}
const hide = {
    strokeDashoffset: "420.7037353515625px",
    transition: ".7s",
    transitionDelay:".4s"
}
const initial_={
    width:"100%",
    opacity:1
}
const final={
    width:"100%",
    opacity:0,
    transition:".3s",
    transitionDelay:".7s"
    
}
// styles end
export default class Loader extends React.Component {
    constructor(props) {
        super(props)
        this.onAnimationEnd = this.onAnimationEnd.bind(this);
    }
    onAnimationEnd() {
        try {
            if (!this.ended) {
                this.props.onAnimationEnd();
                this.ended = true
            }
        } catch (err) {
            console.log(err)
        }
    }
    render() {
        return (
            // ==============================================
            // don't remove html_start and html_end
            //html_start
            <div onTransitionEnd={this.onAnimationEnd} hide={`${this.props.loaded}`} className={Style.loader_div}>
                <svg width="104" height="124" viewBox="0 0 104 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path  style={this.props.loaded ? hide : initial} d="M103 1L3 58.6529L75.8571 94.9529V122L3 85.7L103 28.0471" stroke="aqua" />
                </svg>
                <div className={Style.loader}>
                    <div className={Style.o}>
                        <div style={this.props.loaded ? final : initial_} className={Style.b}></div>
                    </div>
                </div>
            </div>
            //html_end
            // ==============================================
        )
    }
}